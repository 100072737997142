import consumer from "./consumer"

DealerBoost.functions.setupFacebookMessages = function(id) {
    if(DealerBoost.channels.facebookMessagesChannel !== undefined) {
        DealerBoost.channels.facebookMessagesChannel.unsubscribe()
    }

    DealerBoost.channels.facebookMessagesChannel = consumer.subscriptions.create({
        channel: "FacebookMessagesChannel",
        id: id
    }, {
        connected() {
        },
        disconnected() {
        },
        received(data) {
            document.getElementById(data['html_id']).innerHTML = data['html'];
            window.scrollMessageHistoryToBottom(data['chat_id'])
        }
    });
}


